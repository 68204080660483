import { pageNames } from './config'

/**
 * OV Local Membership Page
 * Create a pseduo page in the account area for OV Local membership. Add the
 * OV Local Membership component to show existing points, or sign up to the
 * scheme.
 *
 * We use a component for the OV Local nav item so we can make an
 * API request and show / hide depending on whether the customer qualifies.
 *
 * Components:
 * + OV Local membership nav
 * + OV Local membership
 */

// Page URL
const pageURL = window.location.href

// Query String / URL Params
const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)

// Account pages
if (pageURL.includes('account')) {
  /**
   * Account navigation
   * Add additional link for OV local
   */

  // Nav
  const nav = document.querySelector('.tn-area-nav__list')
  if (nav) {
    // Session detail
    const session = window.dataLayer.find(function (e) { return e.hasOwnProperty('session') }).session
    const encryptedSessionKey = btoa(session.key)

    // Create element - OV local membership nav component
    const OVLocalMembershipNavComponent = document.createElement('twc-account-ov-local-membership-nav')

    // Add dataset
    OVLocalMembershipNavComponent.dataset.tnew = encryptedSessionKey

    // Find memberships nav item
    const OVMembershipsNavItem = [...nav.children].find(child => child.innerHTML.includes(pageNames.memberships))

    if (OVMembershipsNavItem) {
      // Add OV Local Nav component after memberships
      OVMembershipsNavItem.after(OVLocalMembershipNavComponent)
    }

    // Memberships page
    if (pageURL.includes('account/memberships')) {
      /**
       * Remove OV Local membership on memberships page
       */
      const membershipDescriptions = document.getElementsByClassName('tn-membership-summary__description')
      if (membershipDescriptions) {
        const OVLocalMembership = [...membershipDescriptions].find(description => description.innerHTML.includes(pageNames.local))
        if (OVLocalMembership) {
          const OVLocalMembershipWrapper = OVLocalMembership.closest('.tn-account-section-list__item')
          OVLocalMembershipWrapper.remove()
        }

        const memberships = document.getElementsByClassName('tn-account-section-list__item')
        if ([...memberships].length === 0) {
          console.log('no memberships')
        }
      }

      /**
       * Local membership pseudo page & account card
       * Add local membership pseudo page, hide all
       * standard content of page and add account card.
       */
      if (urlParams.get('type') === 'local') {
        // Find memberships nav item
        const membershipsNavItem = [...nav.children].find(child => child.innerHTML.includes(pageNames.memberships))

        // Rename memberships nav item
        membershipsNavItem.innerHTML = `<a href="/account/memberships">${pageNames.memberships}</a>`

        // Components to edit
        const pageHeaderContainer = document.querySelector('.tn-page-layout__page-header-container')
        const pageH1 = document.querySelector('.tn-heading')
        const pageCopy = document.querySelector('.tn-heading-sub-text')

        // Reset page content
        const mainContainer = document.querySelector('.tn-page-layout__main-container')
        mainContainer.innerHTML = ''

        // New page layout
        document.title = `${pageNames.local} | The Old Vic`
        pageH1.innerHTML = pageNames.local
        pageCopy.innerHTML = ''
        mainContainer.append(pageHeaderContainer)

        // Create element - OV local membership component
        const OVLocalMembershipComponent = document.createElement('twc-account-ov-local-membership')

        // Add dataset
        OVLocalMembershipComponent.dataset.tnew = encryptedSessionKey

        // Append OV local membership component
        mainContainer.append(OVLocalMembershipComponent)
      }
    }
  }
}
