/**
 * SYOS MAP ID
 * Add a unique ID to each seatmap class, this allows for
 * seatmap specific styling.
 */

/**
 * Intercept the API call TNEW makes to get the seating data
*/
(function () {
  const product = window.dataLayer.find(function (e) { return e.hasOwnProperty('product') }).product
  const productionSeasonId = product.productionSeasonId

  let oldXHROpen = window.XMLHttpRequest.prototype.open

  window.XMLHttpRequest.prototype.open = function (method, url, async, user, password) {
    this.addEventListener('load', function () {
      if (this.responseText) {
        setTimeout(() => {
          let response = JSON.parse(this.responseText)
          if ('ScreenZoneList' in response && 'seats' in response) {
            const seatmapClassElement = document.querySelector('.tn-syos-seat-map')
            if (seatmapClassElement) {
              let screenNumber = 0
              if (response.ScreenZoneList[0]) {
                screenNumber = response.ScreenZoneList[0].screen_no
              }
              seatmapClassElement.setAttribute('id', `syos-seat-map-${productionSeasonId}-${screenNumber}`)
            }
          }
        }, 10)
      }
    })

    // Pass the call through to the original function
    return oldXHROpen.apply(this, arguments)
  }
})()

