/**
 * Recolors the seating plan so that the color of the seats are consistent
 * across pricing zones.
 *
 * Modified from original setup to allow for mapping colours to specific
 * price bands to keep consistency of colours across sections.
 * @param {object} seatmap
 */
function recolorSeatingPlan (seatmap) {
  const colorsObject = {
    'P': '#FF1C99',
    'O': '#373737',
    'X': '#F42534',
    'A': '#0099FF',
    'B': '#FF8E1C',
    'C': '#5BE300',
    'D': '#9812FF',
    'E': '#00E6DA',
    'F': '#DB3268',
    'J': '#9812FF',
    'K': '#00E6DA',
    'H': '#5BE300',
    'S': '#373737',
    '9': '#373737',
    '4': '#FF1C99',
    '5': '#FF1C99',
    '6': '#FF1C99',
    '7': '#FF1C99',
    '1': '#0099FF',
    '2': '#0099FF',
    '3': '#0099FF'
  }
  const colorSettings = [
    {
      id: 'Charitable',
      priceHigh: 100000,
      priceLow: 181,
      hex: '#F42534'
    },
    {
      id: 'OVP',
      priceHigh: 180,
      priceLow: 150,
      hex: '#373737'
    },
    {
      id: 'Premium',
      priceHigh: 130,
      priceLow: 100,
      hex: '#FF1C99'
    },
    {
      id: 'A',
      priceHigh: 99,
      priceLow: 76,
      hex: '#0099FF'
    },
    {
      id: 'B',
      priceHigh: 75,
      priceLow: 60,
      hex: '#FF8E1C'
    },
    {
      id: 'C',
      priceHigh: 59,
      priceLow: 44,
      hex: '#5BE300'
    },
    {
      id: 'D',
      priceHigh: 43,
      priceLow: 30,
      hex: '#9812FF'
    },
    {
      id: 'E',
      priceHigh: 29,
      priceLow: 22,
      hex: '#00E6DA'
    },
    {
      id: 'F',
      priceHigh: 21,
      priceLow: 11,
      hex: '#DB3268'
    },
    {
      id: 'G',
      priceHigh: 10,
      priceLow: 10,
      hex: '#00B067'
    },
    {
      id: 'H',
      priceHigh: 9,
      priceLow: 0,
      hex: '#3B5DE3'
    }
  ]

  /**
   * Return an object where the keys are the prices and the values are the colors
   * that respresent that price
   * [price => color]
   * @param {array} prices
   * @param {int} productionSeasonId
   * @returns
   */
  function getPriceColorMap (prices) {
    // Find current production season Id
    const product = window.dataLayer.find(function (e) { return e.hasOwnProperty('product') }).product
    const productionSeasonId = product.productionSeasonId

    let _map = {}

    prices.sort((a, b) => parseFloat(a.price) - parseFloat(b.price)).forEach((priceGroup, i) => {
      // Pricing structure - Object mapping
      console.log('productionSeasonId', productionSeasonId)
      if ([22714, 23035].includes(productionSeasonId)) {
        _map[priceGroup.price] = colorsObject[priceGroup.id]

      // Pricing structure - Price range
      } else {
        const priceSettings = colorSettings.find(color => color.priceHigh >= priceGroup.price && color.priceLow <= priceGroup.price)
        if (priceSettings) {
          _map[priceGroup.price] = priceSettings.hex
        }
      }
    })

    return _map
  }

  /**
   * Loop through the seatmap data and build an object to maps seat ids to colors
   * @returns object
   */
  function getSeatMapColors () {
    let seatColors = {}
    seatmap.seats.forEach((seat) => {
      let price = zonePriceMap[seat.zone_no]
      seatColors[seat.seat_no] = priceColorMap[price]
    })
    return seatColors
  }

  /**
   * Recolors the seats in the seatmap based on the seat id
   * @param {object} seatColors
   * @returns
   */
  function setSeatMapColors (seatColors) {
    const seats = document.querySelectorAll('.tn-syos-seat-map__seat')
    if (seats) {
      seats.forEach((seat) => {
        if (seat.style.color) {
          let color = seatColors[seat.dataset.tnSeatId]
          seat.style.color = color
          seat.style.fill = color
        }
      })
      return true
    }
    return false
  }

  /**
   * Updates the price legend to match the new colors for the prices
   * @param {object} prices
   */
  function setPriceLegend (prices) {
    const zoneList = document.querySelector('.tn-syos-legend__zone-list')
    const groups = zoneList.querySelectorAll('.tn-syos-legend__description-group')

    let _prices = []
    for (const price in prices) {
      _prices.push(price)
    }

    _prices = _prices.sort((a, b) => parseFloat(a) - parseFloat(b))

    let max = (_prices.length - 1)

    groups.forEach((zone, i) => {
      if (i > max) {
        zone.remove()
      } else {
        let price = parseFloat(_prices[i]).toFixed(2)
        zone.setAttribute('id', `price-${price}`)
        zone.querySelector('.tn-syos-legend__zone-label').innerText = `£${price}`
        zone.querySelector('.tn-syos-legend__description-term').style.color = prices[_prices[i]]

        // Hide min price range (if exists)
        if (zone.querySelector('.tn-syos-legend__zone-price-range-min-price')) {
          zone.querySelector('.tn-syos-legend__zone-price-range-min-price').style.display = 'none'
        }
        // Hide min price range (if exists)
        if (zone.querySelector('.tn-syos-legend__zone-price-range-max-price')) {
          zone.querySelector('.tn-syos-legend__zone-price-range-max-price').style.display = 'none'
        }
      }
    })
  }

  /**
   * Loop through the data in the TNEW API request and build an objects to map
   * zone numbers to prices.
   * During the loop an array of unique prices is created to use on the pricing
   * legend.
   */
  let zonePriceMap = {}
  const allPrices = []
  seatmap.ZoneColorList.forEach((zone) => {
    allPrices.push({
      id: zone.IconCharacter,
      price: zone.price
    })
    zonePriceMap[zone.zone_no] = zone.price
  })

  const uniquePrices = [...new Map(allPrices.map(item => [item.price, item])).values()]

  let priceColorMap = getPriceColorMap(uniquePrices)

  if (setSeatMapColors(getSeatMapColors())) {
    setPriceLegend(priceColorMap)
  }
}

/**
 * Intercept the API call TNEW makes to get the seating data
 */
(function () {
  let oldXHROpen = window.XMLHttpRequest.prototype.open

  window.XMLHttpRequest.prototype.open = function (method, url, async, user, password) {
    this.addEventListener('load', function () {
      if (this.responseText) {
        let response = JSON.parse(this.responseText)

        if ('ZoneColorList' in response && 'seats' in response) {
          setTimeout(() => {
            recolorSeatingPlan(response)
          }, 2)
        }
      }
    })

    // Pass the call through to the original function
    return oldXHROpen.apply(this, arguments)
  }
})()
