import { pageNames } from '../../account/config'

/**
 * OV Loyalty Page
 * Create a pseduo page in the account area for OV Loyalty. Add the
 * Loyalty card component to show existing points, or sign up to the
 * scheme.
 *
 * Components:
 * + Loyalty account card
 * + Terms and conditions
 */

// Page URL
const pageURL = window.location.href

// Query String / URL Params
const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)

// Account pages
if (pageURL.includes('account')) {
  /**
   * Account navigation
   * Add additional link for OV loyalty,
   * rename memberships nav item to OV together.
   */

  // Nav
  const nav = document.querySelector('.tn-area-nav__list')
  if (nav) {
    // Add navigation item
    const listItem = document.createElement('li')
    listItem.setAttribute('class', 'tn-area-nav__list-item twc-nav-item')
    listItem.innerHTML = `<a href="/account/memberships?type=loyalty-scheme">${pageNames.loyaltyScheme}</a>`

    // Find memberships nav item
    const membershipsNavItem = [...nav.children].find(child => child.innerHTML.includes(pageNames.memberships))

    // Add loyalty page after memberships nav item
    membershipsNavItem.after(listItem)

    // Memberships page
    if (pageURL.includes('account/memberships')) {
      /**
       * Loyalty scheme pseudo page & account card
       * Add loyalty scheme pseudo page, hide all
       * standard content of page and add account card.
       */
      if (urlParams.get('type') === 'loyalty-scheme') {
        membershipsNavItem.innerHTML = `<a href="/account/memberships">${pageNames.memberships}</a>`

        // Components to edit
        const pageHeaderContainer = document.querySelector('.tn-page-layout__page-header-container')
        const pageH1 = document.querySelector('.tn-heading')
        const pageCopy = document.querySelector('.tn-heading-sub-text')

        // Reset page content
        const mainContainer = document.querySelector('.tn-page-layout__main-container')
        mainContainer.innerHTML = ''

        // New page layout
        document.title = `${pageNames.loyaltyScheme} | The Old Vic`
        pageH1.innerHTML = pageNames.loyaltyScheme
        pageCopy.innerHTML = ''
        mainContainer.append(pageHeaderContainer)

        // Fix navigation to highlight OV loyalty item
        membershipsNavItem.innerHTML = `<a href="/account/memberships">${pageNames.memberships}</a>`
        listItem.innerHTML = pageNames.loyaltyScheme

        // Session detail
        const session = window.dataLayer.find(function (e) { return e.hasOwnProperty('session') }).session
        const encryptedSessionKey = btoa(session.key)

        // Create element - Loyalty account card
        const loyaltyAccountCard = document.createElement('twc-loyalty-scheme-account-card')

        // Add dataset
        loyaltyAccountCard.dataset.tnew = encryptedSessionKey
        loyaltyAccountCard.dataset.page = 'account/memberships'

        // Append loyalty account card
        mainContainer.append(loyaltyAccountCard)

        // Create element - Loyalty terms and conditions
        const loyaltyTermsAndConditions = document.createElement('twc-loyalty-scheme-terms-and-conditions')

        // Append loyalty terms and conditions
        mainContainer.append(loyaltyTermsAndConditions)
      }
    }
  }
}
